<script>
  import { Icon } from '@components';

  let { icon, blank, children, ...rest } = $props();

  let target = $state();

  if (blank) {
    target="_blank";
  }

</script>

<style>

  a {
    display: inline-flex;
	align-items: last baseline;

    Icon {
      margin-right: 0 !important;
    }
  }

</style>

<a {target} {...rest}>
  {#if icon}<Icon {icon}>{@render children()}</Icon>
  {:else}
    {@render children()}
  {/if}
</a>
