<script>
  import { Button, Icon } from '@components';

  import sunIcon from '@icons/fa-solid/sun';
  import moonIcon from '@icons/fa-solid/moon';

  import { configStore as config } from '@stores';

  let { mobile, ...rest } = $props();

  let rotate = $state(false);

  const size = mobile ? "1.25rem" : "1rem";

  function onclick(event) {
    rotate = true;
    setTimeout(() => rotate = false, 500);
    config.update({darkMode: !!! $config?.darkMode });
    console.log('Toggle dark mode: ', $config.darkMode);
  }

  $effect(() => {
    document.documentElement.setAttribute('data-theme', $config.darkMode ? 'dark' : 'light');
  });
</script>

<style>
  @keyframes rotate-fade-animation {
    0% {
      transform: rotate(0deg);
      opacity: 0;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }

  Icon {
    margin: 0;
    vertical-align: -.15rem;
    width: 1rem;
    height: 1rem;
    overflow: hidden;

    &.rotate {
      animation: rotate-fade-animation 0.5s ease-in-out;
    }

    &.mobile {
      vertical-align: -.25rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
</style>

<Button class="secondary outline special" {...rest} {onclick}>
  {if $config.darkMode}
    <Icon icon={sunIcon} class:rotate class:mobile {size} />
  {else}
    <Icon icon={moonIcon} class:rotate class:mobile {size} />
  {/if}
</Button>
